<template>
  <div v-if="isReady && hasData">
    <a
      v-for="image in images"
      :key="'adspace-' + code + '-' + image.id"
      class="d-none d-sm-block position-relative mx-auto my-4 w-100 overflow-hidden ad-space"
      :class="`ad-space--${ size }`"
      :href="image.linkToUrl"
      rel="noopener"
      target="_blank"
      :title="image.linkTitleText"
    >
      <img
        class="position-absolute mw-100"
        loading="lazy"
        :src="image.imgSrc"
        :width="size == '4x3' ? 406 : 720"
        :alt="image.imageAltText"
      />
    </a>
  </div>
</template>

<script>
import { AdvertisementGroupService } from "@/services/";

export default {
  name: "app-adspace",
  props: {
    code: { type: String, require: true },
    count: { type: Number, default: 1 },
    size: {
      default: "4x3",
      validator: function(value) {
        return ["wide-short", "4x3"].indexOf(value) !== -1;
      }
    }
  },
  data: function() {
    return {
      hasData: false,
      isReady: false,
      images: [],
      imageUrl: null,
      imageAltText: null,
      linkUrl: null,
      linkTitleText: null
    };
  },
  mounted() {
    AdvertisementGroupService.getRandomAd(this.code, this.count).then(({ data }) => {
      this.images = data.map(({ id, imgAltText, imgSrc, linkTitleText, linkToUrl }) => ({ id, imgAltText, imgSrc, linkTitleText, linkToUrl }));
      this.hasData = this.isReady = true;
    });
  }
};
</script>
